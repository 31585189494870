.modal {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    overflow-y: auto;
  }
  
  .modal-content {
    background-color: #fff;
    margin: 15% auto;
    padding: 20px;
    width: 90%;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    cursor: pointer;
  }
  
  .table-container {
    overflow-x: auto;
    overflow-y: auto;
    height: 300px;
    width: 100%;
  }
  
  .modal-header {
    display: flex;
    justify-content: center;
    padding: 10px;
  }
  
  .standard-button {
    font-size: 1rem;
    padding: 10px 20px;
    display: flex;
    align-items: center;
  }
  
  .flex-row-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }